import { TypographyH1, TypographyMuted } from '@/components/ui/typography'

const FormHeader = () => {
    return (
        <div className="flex items-center p-4 bg-white border-b shadow-lg">
            <div className="flex ml-auto flex-col justify-center gap-1.5 md:items-center">
                <TypographyH1 className="text-lg md:text-2xl text-primary">
                    Pesquisa de satisfação do refeitório
                </TypographyH1>
                <TypographyMuted className="text-xs md:text-sm">
                    Responda as perguntas abaixo de acordo com sua opinião sobre
                    os serviços oferecidos no refeitório
                </TypographyMuted>
            </div>
            <div className="self-start ml-auto md:self-auto">
                {/* <Sidebar isOpen={isOpen} onOpenChange={onOpenChange} /> */}
            </div>
        </div>
    )
}

export default FormHeader
