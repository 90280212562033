import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from '@/pages/Home'
import MainLayout from '@/pages/Layouts/MainLayout'

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<MainLayout />}>
                    <Route path="/">
                        <Route
                            element={
                                <Home />
                            }
                            index
                        />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router
